<template>
  <vz-autocomplete
    value-key="guid"
    value-template="{name}"
    title-template="{name}"
    big
    :modelValue="modelValue"
    :label="label"
    :items="items"
    :use-spinner="useSpinner"
    v-bind="$attrs"
    @search="search"
  >
    <template #item-title="item" v-if="filialOptionalInfo">
      <span class="mr-5">{{ item.name }}</span>
      <vz-tooltip v-if="item.filialOptionalInfo.hasTimetable">
        <vz-icon name="clock-outline" size="14" variant="primary" @click.stop />
        <slot />
        <template #balloon>
          <slot name="baloon">
            Для некоторых филиалов доступен бесплатный простой машины
          </slot>
        </template>
      </vz-tooltip>
    </template>
  </vz-autocomplete>
</template>

<script setup lang="ts">
import VzAutocomplete from '~/components/ui-kit/vz-autocomplete.vue'
import {TradingNetwork} from '~/types/types'
import {getTradingNetworkList} from '@/api/trading-network';

// Условно наследуем компонент от VzAutocomplete
defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: undefined
  },
  filialOptionalInfo: {
    type: [Boolean, String],
    default: '1'
  }
})
const items = ref([] as TradingNetwork[])
const selected = ref({} as TradingNetwork)
const useSpinner = ref(false)
let controller = new AbortController()

async function search(value?: string): Promise<void> {
  useSpinner.value = true
  controller = new AbortController()
  items.value = await getTradingNetworkList({ search: value, filialOptionalInfo: props.filialOptionalInfo }, controller.signal)
  useSpinner.value = false
}

onMounted(() => {
  search('')
})

// Выставляем текущее значение и добавляем его в начало списка
watch(() => [items.value, props.modelValue],
  async () => {
    if (selected.value?.guid === props.modelValue) {
      return
    }
    selected.value = items.value.find(city => city.guid === props.modelValue)
    // if (props.modelValue && !selected.value && items.value.length) {
    //   let selectedLocation = (await getAddressByGuid(props.modelValue))?.location
    //   if (selectedLocation) {
    //     selected.value = selectedLocation
    //     items.value = [selectedLocation, ...items.value]
    //   }
    // }
  }, { immediate: true }
)
defineExpose({
  selected,
  search
})
</script>

<style scoped>

</style>
