<template>
  <vz-autocomplete
    ref="autocomplete"
    value-key="guid"
    value-template="{address}"
    title-template="{address}"
    icon-variant="primary"
    big
    :modelValue="modelValue"
    :label="label"
    :items="items"
    :use-spinner="useSpinner"
    v-bind="$attrs"
    @search="search"
  >
    <template #item-title="item" v-if="showTimetableTooltip">
      <span class="mr-5">{{ item.address }}</span>
      <vz-tooltip v-if="item.timetable">
        <vz-icon name="clock-outline" size="14" variant="primary" @click.stop />
        <slot />
        <template #balloon>
          <slot name="baloon">
            Доступен бесплатный простой машины
          </slot>
        </template>
      </vz-tooltip>
    </template>
  </vz-autocomplete>
</template>

<script setup lang="ts">
import VzAutocomplete from '~/components/ui-kit/vz-autocomplete.vue'
import {TradingNetworkAddress} from '~/types/types'
import {getTradingNetworkAddressList} from '@/api/trading-network';
import {deepCopyObject} from '~/common/functions';

// Условно наследуем компонент от VzAutocomplete
defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  networkGuid: {
    type: String,
    default: ''
  },
  custom: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: undefined
  },
  showTimetableTooltip: {
    type: Boolean,
    default: true
  }
})

const CUSTOM_FILIAL_TEXT = 'Нет нужного филиала'
let controller = new AbortController()

const autocomplete = ref()

const items = ref([] as TradingNetworkAddress[])
const selected = ref({} as TradingNetworkAddress)
const useSpinner = ref(false)

async function search(value?: string): Promise<void> {
  if (!props.networkGuid || value === CUSTOM_FILIAL_TEXT) {
    return
  }
  useSpinner.value = true
  controller = new AbortController()
  const data = await getTradingNetworkAddressList({
    networkGuid: props.networkGuid,
    // perPage: 20,
    search: value || ''
  }, controller.signal)
  if (value || props.custom) {
    const customAddress = {
      address: CUSTOM_FILIAL_TEXT,
      guid: '',
      networkGuid: props.networkGuid,
      note: value
    } as TradingNetworkAddress
    data.unshift(customAddress)
  }
  items.value = data
  useSpinner.value = false
}

// Выставляем текущее значение и добавляем его в начало списка
watch(() => [items.value, props.modelValue],
  async () => {
    if (selected.value?.guid === props.modelValue) {
      return
    }
    const find = items.value.find(branch => branch.guid === props.modelValue)
    if (find) {
      selected.value = deepCopyObject(find)
    }
  }, { immediate: true }
)

watch(() => props.networkGuid,
  () => {
    if (!props.modelValue && autocomplete.value?.inputValue) {
      items.value = []
      autocomplete.value.inputValue = ''
    }
    search()
  },
  { immediate: true }
)
defineExpose({
  selected,
  search
})
</script>

<style scoped>

</style>
